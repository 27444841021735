import httpClient  from '.';
const API_CONTROLLER = 'report-vehicle/';

export default {
    getReportRegistration(search) {
        let url = API_CONTROLLER + 'get-registration';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                vehicleId: search.vehicleId
            }
        });
    },
}